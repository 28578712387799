// Utility to log only in dev
// This helps us debug, while keeping sensitive information secure

import { isClient, isDeployed, isWebworker } from '@/util/env'

// Parse the URL to see if a narrator_debug query param is present!
// NOTE: value does not matter, if the param is present, we enable debug logs
const entryUrl = isClient && !isWebworker ? new URL(document.location.href) : undefined
const debugEnabled = entryUrl && entryUrl.searchParams.has('narrator_debug')

export default function debug(...args: any[]) {
  if (debugEnabled || !isDeployed) {
    console.log(...args)
  }
}
