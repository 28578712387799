// Persists the last viewed company's slug and id in localstorage for convenience and faster init

import { ICompany } from 'graph/generated'

type IPersistedCompany = Pick<ICompany, 'id' | 'slug'>
type PersistedCompanyResult = Partial<IPersistedCompany>

const NARRATOR_COMPANY_KEY = 'narrator_company'

export const persistCompany = (company: IPersistedCompany): void => {
  localStorage.setItem(NARRATOR_COMPANY_KEY, JSON.stringify(company))
}

export const loadPersistedCompany = (): PersistedCompanyResult => {
  try {
    return JSON.parse(localStorage.getItem(NARRATOR_COMPANY_KEY) || '{}')
  } catch {
    clearPersistedCompany()
    return {}
  }
}

export const clearPersistedCompany = () => {
  localStorage.removeItem(NARRATOR_COMPANY_KEY)
}
