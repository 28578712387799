import { GetServerSideProps } from 'next'

// NOTE this is re-written as @sentry/react for the client
import * as Sentry from '@sentry/node'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { RewriteFrames } from '@sentry/integrations'

import { isClient } from '@/util/env'
import history from '@/util/history'

// Initialize Sentry
Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: !!process.env.NEXT_PUBLIC_SENTRY_DSN,
  release: process.env.BUILD_ID,
  environment: process.env.NEXT_PUBLIC_IS_PRODUCTION ? 'production' : 'preview',
  tracesSampleRate: 0.25,
  normalizeDepth: 10,
  ignoreErrors: [
    // https://stackoverflow.com/a/50387233
    'ResizeObserver loop limit exceeded',
  ],
  integrations: (integrations) => {
    let result = [...integrations]

    if (isClient) {
      result = result.concat(
        new TracingIntegrations.BrowserTracing({
          tracingOrigins: ['localhost', '.narrator.ai'],
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: types are for node sentry but we're using react sentry here via webpack alias
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        })
      )
    } else {
      result = result.concat(new Sentry.Integrations.Http({ tracing: true, breadcrumbs: true }))

      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      result = result.concat(
        new RewriteFrames({
          iteratee: (frame) => {
            if (frame.filename) {
              // /var/task is the root directory containing Next.js's build output in the Vercel environment
              frame.filename = frame.filename.replace('/var/task', 'app:///')
              frame.filename = frame.filename.replace('.next', '_next')
            }
            return frame
          },
        })
      )
    }

    return result
  },
})

export default Sentry

/*
  This can be used as `getServerSideProps` on pages to wrap them in Sentry tracing.
  You will need to inject a trace header into the page meta via props, see example in `DynamicPortalRoute`

  To use you can add:
    export { getSentryServerSideProps as getServerSideProps } from '@/util/sentry'
  to your page component, or compose it into a custom `getServerSideProps`

  NOTE using this forces the page into being server-generated, and should not be used for pages
  that can be rendered statically / don't do anything interesting on the server
*/
export const getSentryServerSideProps: GetServerSideProps = async (context) => {
  const transaction = Sentry.startTransaction({
    op: 'transaction',
    name: 'server.render',
  })

  Sentry.configureScope((scope) => {
    scope.setSpan(transaction)
  })

  context.req.on('close', () => {
    transaction.finish()
  })

  return {
    props: {
      server: {
        trace: transaction.toTraceparent(),
      },
    },
  }
}
