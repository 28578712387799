import { useEffect, useState } from 'react'
import { RedirectLoginOptions } from '@auth0/auth0-spa-js'

import { loadPersistedCompany } from 'util/persistCompany'
import usePrevious from 'util/usePrevious'
import debug from 'util/debug'

import isEmail from 'validator/lib/isEmail'

import { isClient } from '@/util/env'

// Routes that may come in as the first path that are _not_ company slugs
export const notCompanySlugs = [
  'null',
  'undefined',
  'callback',
  'login',
  'new',
  'signup',
  'welcome',
  'admin',
  'api',
  'mavis',
]

// Create an access scope for authorization request
export const scopeWithCompany = (slug: string | undefined) => {
  if (slug) {
    return `access:${slug}`
  } else {
    return undefined
  }
}

// Parse a successful auth response JWT's scope for company
export const parseScopeForCompany = (scope: string | undefined, company: string | undefined) => {
  const accessScopes = scope?.split(' ').filter((s) => s.startsWith('access:'))

  let accessScope = null

  if (company) {
    accessScope = accessScopes?.find((scope) => scope === `access:${company}`)
  } else {
    accessScope = accessScopes?.[0]
  }

  const accessScopeCompany = accessScope?.split(':')[1]
  return accessScopeCompany
}

export const redirectOrigin = isClient ? `${window.location.origin}/callback` : '/callback'

interface HandleLoginOptions {
  login(o: RedirectLoginOptions): void
  rdrct?: string
  companySlug?: string
  signup?: boolean
  signupEmail?: string
}
export const handleLogin =
  ({ login, rdrct, companySlug, signup, signupEmail }: HandleLoginOptions) =>
  () => {
    const additionalSignupOptions: Pick<RedirectLoginOptions, 'screen_hint' | 'login_hint'> = {}
    if (signup) {
      // Shows the signup page instead of the login page:
      additionalSignupOptions.screen_hint = 'signup'

      // Prefills the email field when possible for a signup
      if (signupEmail && isEmail(signupEmail)) {
        additionalSignupOptions.login_hint = signupEmail
      }
    }

    // Redirecting to auth0 from within a Cypress test causes a "Cypress detected a cross origin error happened on page load" error
    // Only allow this to happen from specs that start with the name 'login'
    if (window.Cypress && !window.Cypress.spec.name.startsWith('e2e/login')) {
      debug('Skipping redirect to login within Cypress test', { rdrct, companySlug, signup, additionalSignupOptions })
      return
    }

    login({
      // display: 'page',
      // prompt: 'select_account',
      // connection: 'google-oauth2',
      appState: {
        rdrct,
        companySlug,
      },
      redirect_uri: redirectOrigin,
      ...additionalSignupOptions,
    })
  }

export const inferCompanySlug = () => {
  if (isClient) {
    const slugInUrl = window.location.pathname.split('/').filter(Boolean)[0]
    if (slugInUrl && !notCompanySlugs.includes(slugInUrl)) {
      return slugInUrl
    }
  }
  return loadPersistedCompany().slug
}

export const useGuessCompanySlug = () => {
  const inferredCompany = inferCompanySlug()
  const prevInferredCompany = usePrevious(inferredCompany)
  const [companySlug, setCompanySlug] = useState<string | undefined>(inferredCompany)
  const prevCompanySlug = usePrevious(companySlug)

  useEffect(() => {
    if (inferredCompany !== prevInferredCompany) {
      const updatedCompany = inferCompanySlug()
      if (updatedCompany !== prevCompanySlug) {
        debug('Guessing company slug', updatedCompany)
        setCompanySlug(updatedCompany)
      }
    }
  }, [setCompanySlug, inferredCompany, prevInferredCompany, prevCompanySlug])

  return { guessCompanySlug: companySlug, setGuessCompanySlug: setCompanySlug }
}
