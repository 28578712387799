import { createBrowserHistory, createMemoryHistory } from 'history'
import { isServer } from '@/util/env'
import analytics from '@/portal/util/analytics'

const history = isServer ? createMemoryHistory() : createBrowserHistory()

// Track client routing
if (!isServer) {
  history.listen(() => {
    // NOTE - this is subsequent page views
    // Initial page view is in index.tsx where we render <Root>
    analytics.page()
  })
}

export default history
